import React, { useEffect, useCallback, useState } from 'react'
import { Link, graphql } from 'gatsby'
import Footer from '../components/footer'
import Nav from '../components/nav'
import Entries from '../components/entries'

import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from '../components/seo'
import AllGoogleReviews from '../components/all_google_reviews'

const HomePage = ({data}) => {

  const [emblaRef, emblaApi] = useEmblaCarousel({ 
    loop: true ,
  }, [Autoplay()]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowLeft') {
        scrollPrev();
      } else if (e.key === 'ArrowRight') {
        scrollNext();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [scrollPrev, scrollNext]);

  const entry = data.markdownRemark
  const left_image = getImage(entry.frontmatter.left_image)
  const before_image = getImage(entry.frontmatter.before_image)
  const after_image = getImage(entry.frontmatter.after_image)
  const large_image = getImage(entry.frontmatter.large_image)

	return (
    <div>
      <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
      <Nav />

      <div className="w-full relative">
        <button className="z-30 absolute top-1/2 -translate-y-1/2 left-6 md:left-12 cursor-pointer text-white hover:text-white/50 disabled:hover:text-white/25 disabled:opacity-25" onClick={scrollPrev}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-12 h-12">
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
          </svg>
        </button>
        <button className="z-30 absolute top-1/2 -translate-y-1/2 right-6 md:right-12 cursor-pointer text-white hover:text-white/50 disabled:hover:text-white/25 disabled:opacity-25" onClick={scrollNext}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-12 h-12">
            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
          </svg>
        </button>
        <div className="relative">
          <div ref={emblaRef}>
            <div className="flex">
              {entry.frontmatter.banner.map((entry, i) => {
              const image = getImage(entry.image)
              return (
                <div key={`b_${i}`} className="flex-[0_0_100%] relative">
                  <>
                    <div className="absolute w-full h-full -inset-0 z-10 bg-black/25" /> 
                    <div className="absolute z-20 flex items-center justify-center text-center w-full h-full">
                      <div className="w-10/12 lg:w-2/3">
                        <p className="text-lg text-white/75 mb-3">{entry.heading}</p>
                        <h1 className="font-sans font-bold text-4xl lg:text-7xl text-white leading-none">{entry.text}</h1>
                      </div>
                    </div>
                    <div className="relative">
                      <GatsbyImage image={image} alt="" style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} /> 
                    </div>
                  </>
                </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full py-24 bg-dark-grey">
        <div className="w-11/12 mx-auto max-w-screen-3xl">
          <div className="flex flex-col md:flex-row justify-center items-center gap-24">
            <div className="w-full md:w-1/2">
              <GatsbyImage image={left_image} alt="" style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
            </div>
            <div className="w-full md:w-1/2">
              <div className="max-w-none prose text-xl font-sans leading-normal prose-h2:text-white prose-h3:text-white prose-p:text-white/75 prose-strong:text-blue">
                <h2>{entry.frontmatter.left_image_heading}</h2>
                <p>{entry.frontmatter.left_image_text}</p>
              </div>
              <div className="mt-12 flex gap-6">
                <Link href="/contact/" className="bg-red text-xl font-bold text-white px-6 py-3 hover:text-white hover:underline">
                  Get a free quote
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full py-24 bg-white">
        <div className="w-11/12 mx-auto max-w-screen-3xl">
          <div className="flex flex-col md:flex-row justify-center items-center text-center gap-24">
            <div className="w-full md:w-2/3">
              <div className="max-w-none prose text-xl font-sans leading-normal prose-h2:text-blue prose-h3:text-dark-grey prose-p:text-dark-grey/75 prose-a:text-blue prose-strong:text-dark-grey">
                <h2>{entry.frontmatter.intro_heading}</h2>
                <p>{entry.frontmatter.intro_text}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Entries data={entry.frontmatter.entries} />

      <div className="w-full py-24 bg-white">
        <div className="w-11/12 mx-auto max-w-screen-3xl">
          <div className="flex flex-col md:flex-row justify-center items-center gap-24">
            <div className="w-full md:w-1/2">
              <div className="mb-6 relative">
                <div className="shadow-lg text-2xl absolute z-10 left-0 top-12 font-sans font-bold bg-blue text-white px-6 py-2">Before</div>
                  <GatsbyImage image={before_image} alt="" style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
              </div>
              <div className="relative">
                <div className="shadow-lg text-2xl absolute z-10 left-0 top-12 font-sans font-bold bg-dark-grey text-white px-6 py-2">After</div>
                  <GatsbyImage image={after_image} alt="" style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <div className="max-w-none prose text-xl font-sans leading-normal prose-h2:text-blue prose-h3:text-blue prose-p:text-dark-grey/75 prose-strong:text-blue">
                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
              </div>
              <div className="mt-12 flex gap-6">
                <Link href="/contact/" className="bg-red text-xl font-bold text-white px-6 py-3 hover:text-white hover:underline">
                  Get a free quote
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pt-96 pb-12 lg:pb-24 relative">
        <div className="w-full h-full absolute -inset-0 z-10">
          <GatsbyImage image={large_image} alt="" style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
        </div>
        <div className="w-full relative z-20">
          <div className="w-11/12 mx-auto max-w-screen-3xl">
            <div className="flex flex-col md:flex-row justify-end items-center gap-24">
              <div className="w-11/12 lg:w-1/2 max-w-none prose text-xl font-sans leading-normal prose-h2:m-0 prose-h2:mb-6 prose-h2:text-white prose-p:text-white/75 prose-strong:text-white">
                <div className="bg-blue px-6 lg:px-12 pt-6 lg:pt-12 pb-6 lg:pb-12">
                  <div>
                    <h2>{entry.frontmatter.large_image_heading}</h2>
                    <p>{entry.frontmatter.large_image_text}</p>
                  </div>
                  <div className="mt-6 flex gap-6">
                    <Link href="/contact/" className="inline-block border-2 border-white text-xl font-bold text-white px-6 py-3 hover:text-white no-underline">
                      Get a free quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full py-24 bg-grey">
        <div className="w-11/12 mx-auto max-w-screen-3xl">
          <div className="flex flex-col md:flex-row justify-center items-center text-center gap-24">
            <div className="w-full md:w-2/3">
              <div className="max-w-none prose text-xl font-sans leading-normal prose-h2:text-dark-grey prose-h3:text-dark-grey prose-p:text-dark-grey/75 prose-a:text-blue prose-strong:text-dark-grey">
                <h2>{entry.frontmatter.reviews_heading}</h2>
                <p>{entry.frontmatter.reviews_text}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AllGoogleReviews />

      <div className="w-full py-24 bg-dark-grey font-sans text-white/75 text-xl">
        <div className="w-11/12 mx-auto max-w-screen-3xl">
          <div className="flex flex-col md:flex-row gap-24">
            <div className="w-full md:w-1/3">
              <ul className="flex flex-col gap-2">
                <li className="text-white mb-3"><strong>Roof Painting</strong></li>
                <li><Link href="/roof-painting/concrete-roof-tiles/" className="hover:text-white hover:underline">Concrete Roof Tiles</Link></li>
                <li><Link href="/roof-painting/iron-roofs/" className="hover:text-white hover:underline">Iron Roofs</Link></li>
                <li><Link href="/roof-painting/roof-plus-colour-chart/" className="hover:text-white hover:underline">Procoat Colour Chart</Link></li>
                <li><Link href="/roof-painting/paint-and-warranty/" className="hover:text-white hover:underline">Roof & Paint Warranty</Link></li>
                <li><Link href="/roof-painting/steep-roofs/" className="hover:text-white hover:underline">Steep Roofs</Link></li>
              </ul>
            </div>
            <div className="w-full md:w-1/4">
              <ul className="flex flex-col gap-2">
                <li className="text-white mb-3"><strong>House Painting</strong></li>
                <li><Link href="/house-painting/exterior-painting/" className="hover:text-white hover:underline">Exterior</Link></li>
                <li><Link href="/house-painting/interior-painting/" className="hover:text-white hover:underline">Interior</Link></li>
              </ul>
            </div>
            <div className="w-full md:w-1/4">
              <ul className="flex flex-col gap-2">
                <li className="text-white mb-3"><strong>House Washing</strong></li>
                <li><Link href="/house-washing/moss-and-mould-treatment/" className="hover:text-white hover:underline">Moss &amp; Mould Treatment</Link></li>
                <li><Link href="/house-washing/water-blasting/" className="hover:text-white hover:underline">Water Blasting</Link></li>
              </ul>
            </div>
            <div className="w-full md:w-1/4">
              <div className="flex justify-start md:justify-end text-left md:text-right">
                <ul className="flex flex-col gap-2">
                  <li className="text-white mb-3"><strong>About</strong></li>
                  <li><Link href="/testimonials/" className="hover:text-white hover:underline">Reviews</Link></li>
                  <li><Link href="/faq/" className="hover:text-white hover:underline">Frequently Asked Questions</Link></li>
                  <li><Link href="/gallery/" className="hover:text-white hover:underline">Projects</Link></li>
                  <li><Link href="/commercial/" className="hover:text-white hover:underline">Commercial</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
	)
}

export default HomePage;

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
        title
				page_title
				meta_description
        banner {
          image {
            childImageSharp {
              gatsbyImageData(width: 1024, height: 768)
            }
          }
          heading
          text
        }
        left_image {
          childImageSharp {
            gatsbyImageData(width: 1280)
          }
        }
        left_image_heading
        left_image_text
        intro_heading
        intro_text
        entries {
          image {
            childImageSharp {
              gatsbyImageData(width: 1024, height: 768)
            }
          }
          heading
          text
          link
        }
        before_image {
          childImageSharp {
            gatsbyImageData(width: 1280)
          }
        }
        after_image {
          childImageSharp {
            gatsbyImageData(width: 1280)
          }
        }
        large_image {
          childImageSharp {
            gatsbyImageData(width: 1280)
          }
        }
        large_image_heading
        large_image_text
        reviews_heading
        reviews_text
			}
			fields {
				slug
			}
      html
		}
	}
`