import React, { useEffect, useCallback, useState } from 'react'
import { Link } from 'gatsby'

import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Entries = ({data}) => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const [emblaRef, emblaApi] = useEmblaCarousel({ 
    loop: true ,
  }, [Autoplay()]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback(() => {
    if (emblaApi) {
      setPrevBtnDisabled(!emblaApi.canScrollPrev())
      setNextBtnDisabled(!emblaApi.canScrollNext())
    }
  }, [emblaApi]);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', onSelect);
      onSelect();
    }
  }, [emblaApi, onSelect]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowLeft') {
        scrollPrev();
      } else if (e.key === 'ArrowRight') {
        scrollNext();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [scrollPrev, scrollNext]);

  //console.log('entries: ', data)

  return (
    <div className="w-full py-24 relative bg-grey">
      <button className="z-30 absolute top-1/2 -translate-y-1/2 left-6 md:left-12 cursor-pointer text-white hover:text-black disabled:hover:text-black disabled:opacity-25" disabled={prevBtnDisabled} onClick={scrollPrev}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-12 h-12">
          <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
        </svg>
      </button>
      <button className="z-30 absolute top-1/2 -translate-y-1/2 right-6 md:right-12 cursor-pointer text-white hover:text-black disabled:hover:text-black disabled:opacity-25" disabled={nextBtnDisabled} onClick={scrollNext}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-12 h-12">
          <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
        </svg>
      </button>
      <div className="overflow-hidden relative w-full h-full">
        <div ref={emblaRef}>
          <div className="flex w-full h-full">

          {data.map((entry, i) => {
            const image = getImage(entry.image)
            return (
              <div key={i} className="flex-[0_0_75%] lg:flex-[0_0_25%] mr-6">
                <div className="bg-white w-full h-full">
                  <Link href={entry.link} className="group">
                    <div>
                      <div className="overflow-hidden">
                        <GatsbyImage className="object-cover w-full h-full transition-all duration-500 group-hover:scale-100 lg:group-hover:scale-105" image={image} alt="" style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
                      </div>
                    </div>
                    <div className="px-6 pt-6 pb-12">
                      <div className="text-center font-sans prose text-xl prose-h2:mb-3 prose-h2:text-blue prose-p:text-dark-grey/75 leading-normal">
                        <h3 className="group-hover:underline">{entry.heading}</h3>
                        <p>{entry.text}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Entries;