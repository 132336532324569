import React from 'react';
import { StaticQuery, graphql } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

function CreateRatings(data) {
  let ratings = []
  for (let i = 0; i < 5; ++i) {
    if (data.rating > i) {
      ratings.push(<span className="text-yellow" key={i}>&#9733;</span>)
    } else {
      ratings.push(<span className="text-grey-9" key={i}>&#9733;</span>)
    }
  }
  return (
    <>
      {ratings}
    </>
  )
}

function GoogleReviews(data) 
{
  return (
    <>
    {data.reviewItems.map(({node}) => {
      let excerpt = node.text.length > 100 ? node.text.substring(0, 100) + "..." : node.text;
      return (
        <div key={node.author_name} className="bg-white p-6">
          <div className="text-lg space-x-3 inline-block mt-1 mb-3">
            <CreateRatings rating={node.rating} />
          </div>
          <div className="max-w-none prose text-xl font-sans leading-normal prose-h2:text-blue prose-h3:text-dark-grey prose-p:text-dark-grey/75 prose-a:text-blue prose-strong:text-dark-grey">
            <p>&#8220;{excerpt}&#8221;</p>
          </div>
          <p className="mt-6 font-bold text-blue">&mdash; {node.author_name} <small className="text-grey-9 font-normal">({node.relative_time_description})</small> <StaticImage style={{ display: "inline-block", width: "20px" }} src="../images/google_sm.png" alt="" /></p>
        </div>
      )
    })}
    </>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
    {
      googleReviewsTotalRatings {
        total_rating
        total_reviews
      }
      allGoogleReviews {
        edges {
          node {
            author_name
            author_url
            relative_time_description
            rating
            text
            location
            time
          }
        }
      }
    }
  `} 
  render = { data => (
    <>
    <div className="w-full pb-24 bg-grey">
        <div className="w-11/12 mx-auto max-w-screen-3xl">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div className="bg-white p-6 flex items-center justify-center text-center">
              <div>
                <div>
                  <span className="text-black text-3xl font-bold leading-snug mb-1 block">Google Rating</span>
                  <span className="text-yellow text-3xl font-bold leading-snug mb-1 block flex justify-center items-center space-x-3">
                    <span>{data.googleReviewsTotalRatings.total_rating}</span> <span className="text-lg text-yellow">&#9733;</span> <span className="text-lg text-yellow">&#9733;</span> <span className="text-lg text-yellow">&#9733;</span> <span className="text-lg text-yellow">&#9733;</span> <span className="text-lg text-yellow">&#9733;</span>
                  </span>
                  <span className="text-grey-9 text-sm leading-snug mb-3 block">based on {data.googleReviewsTotalRatings.total_reviews} reviews</span>
                </div>
                <div>
                  <a href="https://search.google.com/local/reviews?placeid=ChIJmeH8LPtIDW0Rb8c4bcxNdIw" target="_blank" className="text-green text-base font-bold border-b-2 border-green" title="More google reviews" rel="noopener nofollow">More reviews</a>
                </div>
              </div>
            </div>
            <GoogleReviews reviewItems={data.allGoogleReviews.edges} />
          </div>
        </div>
      </div>
    </>
  )}
  />
)